import Benefits from './Benefits'
import Catalog from './Catalog'
import Footer from './Footer'
import FrequentQuestions from './FrequentQuestions'
import Hero from './Hero'
import HowBuy from './HowBuy'
import Partners from './Partners'

export default function Main() {
  return (
    <div>
      <Hero />
      <HowBuy />
      <Benefits />
      <Catalog />
      <Partners />
      <FrequentQuestions />
      <Footer />
    </div>
  )
}
