import './Benefits.scss'
import checkWhite from '../../../images/icons/CheckIconWhite.svg'

export default function Benefits() {
  return (
    <section className="sectionBenefits">
      <div className="containerBenefitsBannerFull">
        <div className="containerBenefitsBannerSub">
          <div className="containerTextSubBanner">
            <h2>LIGADO NA SUA BIKE,</h2>
            <h2>LIGADO EM VOCÊ</h2>
          </div>
        </div>
      </div>
      <div className="containerBenefitsDescription">
        <div className="containerDescription1">
          <div className="containerImg">
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/parceriaBco.png?v=637394270664500000"
              alt="Imagem de parceria"
              width={108}
            />
          </div>
          <div className="containerText">
            <h2>
              LIGADO
              <br /> NA PARCERIA
            </h2>
            <p className="containerBenefitsText">
              <img src={checkWhite} alt="check" />
              Reposição rápida por bike ou peças novas
            </p>
          </div>
        </div>
        <div className="containerDescription2">
          <div className="containerImg">
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/cuidadoBco.png?v=637394270674030000"
              alt="Imagem de cuidado"
              width={108}
            />
          </div>
          <div className="containerText">
            <h2>
              LIGADO
              <br /> NO CUIDADO
            </h2>
            <p className="titleBenefits">COBRE:</p>
            <p className="containerBenefitsText">
              <img src={checkWhite} alt="check" />
              Subtração (roubo ou furto qualificado)
            </p>
            <p className="containerBenefitsText">
              <img src={checkWhite} alt="check" />
              Danos acidentais
            </p>
            <p className="containerBenefitsText">
              <img src={checkWhite} alt="check" />
              Em posse de terceiros
            </p>
            <p className="containerBenefitsText">
              <img src={checkWhite} alt="check" />
              Em transporte
            </p>
          </div>
        </div>
        <div className="containerDescription3">
          <div className="containerImg">
            <img
              src="https://decathlonstore.vteximg.com.br/arquivos/diversidadeBco.png?v=637394270668870000"
              alt="Imagem de gesto de paz"
              width={108}
            />
          </div>
          <div className="containerText">
            <h2>
              LIGADO
              <br /> NA DIVERSIDADE
            </h2>
            <p className="titleBenefits">PARA:</p>
            <p className="containerBenefitsText">
              <img src={checkWhite} alt="check" />
              Todos os modelos
            </p>
            <p className="containerBenefitsText">
              <img src={checkWhite} alt="check" />
              Todos os preços
            </p>
            <p className="containerBenefitsText">
              <img src={checkWhite} alt="check" />
              Todos os tipos
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
