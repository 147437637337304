import './Partners.scss'
import checkBlue from '../../../images/icons/CheckIconBlue.svg'

export default function Partners() {
  return (
    <section className="sectionPartners">
      <div>
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/clube-decathlon.png?v=636983675989630000"
          alt="Logo do Clube Decathlon"
          width={250}
        />
      </div>
      <div className="containerClubeDecathlon">
        <h2>
          MAIS BENEFÍCIOS,
          <br /> MAIS ESPORTE
        </h2>
        <p className="descriptionClubeDecathlon">
          Ao adquirir seu seguro B•ON você fará parte do Clube Decathlon, nosso
          programa de relacionamento gratuito que oferece benefícios exclusivos
          como:
        </p>
        <p className="containerTextParners">
          <img src={checkBlue} alt="check" />
          1ª Revisão da bike Decathlon
        </p>
        <p className="containerTextParners">
          <img src={checkBlue} alt="check" />
          Troca estendida e sem cupom
        </p>
        <p className="containerTextParners">
          <img src={checkBlue} alt="check" />
          Sorteios
        </p>
        <span>
          E muito mais! Confira{' '}
          <a href="https://www.decathlon.com.br/clube/?_ga=2.42865068.801093965.1674737412-1602741231.1670424717">
            aqui
          </a>
        </span>
      </div>
      <div>
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/clube-santuu-logo.png?v=637394270591000000"
          alt="Logo do Clube Santuu"
          width={250}
        />
      </div>
      <div className="containerClubeSantuu">
        <h2>A PLATAFORMA OFICIAL DO SEGURO B•ON </h2>
        <p>
          Um clube de produtos, serviços e benefícios só para ciclistas.
          Seguros, eventos, pontos de apoio, financiamentos, rede de descontos e
          de contatos.
        </p>
        <span>
          Acesse o{' '}
          <a href="https://app.clubesantuu.com.br/new/simulation/bike-info/?key=2022071621321235&program=decathlon">
            Clube Santuu
          </a>
        </span>
      </div>
    </section>
  )
}
