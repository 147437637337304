import data from './data.json'
import Question from './Question'
import './FrequentQuestions.scss'

export default function FrequentQuestions() {
  return (
    <section className="sectionFrequentQuestions">
      <div className="containerFrequentQuestions">
        <h2>PERGUNTAS FREQUENTES</h2>
        <div className="containerQuestions">
          {data.map((question, index) => (
            <Question key={index} data={question} />
          ))}
        </div>
      </div>
    </section>
  )
}
