import './Catalog.scss'
import Number1 from '../../../images/icons/number1Icon.svg'
import Number2 from '../../../images/icons/number2Icon.svg'
import Number3 from '../../../images/icons/number3Icon.svg'

export default function Catalog() {
  return (
    <section className="sectionCatalog">
      <div className="containerCatalogCarrosel">
        <div className="containerCarrosel-left">
          <div className="containerCarroselTitle">
            <h2>PREÇO JUSTO,</h2>
            <h2 className="breakLine">PAGAMENTO FACILITADO</h2>
          </div>
          <div className="center">
            <div>
              <h3>
                Bikes e seguros <br /> em até <span>10x SEM JUROS*</span>
              </h3>
              <p>PROTEÇÃO IMEDIATA</p>
              <p>12 MESES DE TRANQUILIDADE</p>
            </div>
          </div>
        </div>
        {/* <div className="containerCarrosel-right">
          Carrosel
          <p>
            *Consultar o preço vigente e ver outros modelos nas lojas físicas
            Decathlon ou no site
          </p>
        </div> */}
      </div>
      <div className="containerCatalogInstructions">
        <div className="containerInstructions-left">
          <h2>
            SINISTRO DE QUEBRA
            <br /> OU ROUBO?
          </h2>
          <h3>É MUITO FÁCIL RESOLVER:</h3>
        </div>
        <div className="containerInstructions-right">
          <div className="containerInstructionText">
            <img src={Number1} alt="number 1" />
            <a href="https://app.clubesantuu.com.br/new/simulation/bike-info/?key=2022071621321235&program=decathlon">
              Você acessa o Clube Santuu
            </a>
          </div>
          <div className="containerInstructionText">
            <img src={Number2} alt="number 1" />
            <p>Entra na área de Sinistros {'>'} Comunicar</p>
          </div>
          <div className="containerInstructionText">
            <img src={Number3} alt="number 1" />
            <p>Envia as informações e fotos solicitadas</p>
          </div>
          <span>
            Simples assim! Após aberto esse processo de sinistro, é só aguardar.
            A equipe do Clube Santuu entrará em contato para informar em qual
            loja Decathlon você poderá retirar a sua bicicleta nova ou levar sua
            bike para manutenção, em caso de quebra.
          </span>
        </div>
      </div>
      <div className="containerCatalogIlustration">
        <div className="container-left">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/BON-LOGO.png?v=637394270641830000"
            alt="Logo do Seguro"
          />
          <h2>FAÇA VOCÊ MESMO, AGORA!</h2>
          <p>
            Acesse o{' '}
            <a href="https://app.clubesantuu.com.br/new/simulation/bike-info/?key=2022071621321235&program=decathlon">
              Clube Santuu
            </a>
          </p>
        </div>
        <div className="container-right">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/garotas-bike.jpg"
            alt="Duas pessoas andando de bicicleta"
          />
        </div>
      </div>
    </section>
  )
}
