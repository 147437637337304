import { GatsbySeo } from 'gatsby-plugin-next-seo'
import SecureBon from 'src/components/Campanha/SeguroBon/Main'

export default function Page() {
  return (
    <>
      <GatsbySeo title="Seguro BON | Decathlon" />
      <SecureBon />
    </>
  )
}
