import { Link } from 'gatsby'

import whatsapp from '../../../images/icons/whatsapp.svg'
import mail from '../../../images/icons/mail.svg'

export default function Footer() {
  return (
    <section className="flex flex-col md:flex-row bg-black p-6 sm:p-12">
      <div className="flex flex-col w-full mb-3 sm:mb-0 sm:w-5/13 justify-center">
        <h3 className="italic text-4xl text-white mb-10">
          FALE COM O CLUBE SANTUU
        </h3>
        <div className="flex flex-col">
          <Link
            to="https://api.whatsapp.com/send?phone=5511934862272"
            className="flex items-center"
          >
            <img
              className="pr-1"
              src={whatsapp}
              alt="Icone do whatsapp"
              width={30}
              height={30}
            />
            <h5 className="text-white">11 93486-2272</h5>
          </Link>
          <Link
            to="mailto:contato@clubesantuu.com.br"
            className="flex items-center"
          >
            <img
              className="pr-1"
              src={mail}
              alt="Icone do mail"
              width={30}
              height={30}
            />
            <h5 className="text-white">contato@clubesantuu.com.br</h5>
          </Link>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col sm:flex-row">
          <div className="flex flex-wrap items-center">
            <img
              className="p-4 w-1/2 sm:w-1/4"
              src="https://decathlonstore.vteximg.com.br/arquivos/logo-assurant.png?v=637951611852700000"
              alt="Assurant Logo"
            />
            <img
              className="p-4 w-1/2 sm:w-1/4"
              src="https://decathlonstore.vteximg.com.br/arquivos/clube-santuu-logo.png?v=637394270591000000"
              alt="Clube Santuu Logo"
            />
            <img
              className="p-4 w-1/2 sm:w-1/4"
              src="https://decathlonstore.vteximg.com.br/arquivos/dacath-fdo-pt.png?v=637394270582700000"
              alt="Decathlon Logo"
            />
            <img
              className="p-4 w-1/2 sm:w-1/4"
              src="https://decathlonstore.vteximg.com.br/arquivos/clube-decathlon.png?v=636983675989630000"
              alt="Clube Decathlon Logo"
            />
          </div>
        </div>
        <div>
          <p className="text-white">
            Seguros garantidos por Assurant Seguradora S/A. Seguro Para
            Bicicletas – Processo SUSEP 15414.901278/2014-15 – Ramo 0171. O
            registro do produto é automático e não representa aprovação ou
            recomendação por parte da Susep. Código do Corretor Susep:
            202017848. GHISALLO – CNPJ: 33.544.366/0001-80
          </p>
        </div>
      </div>
    </section>
  )
}
