import './Hero.scss'

export default function Hero() {
  return (
    <section className="sectionHero">
      <div className="containerHero">
        <div className="containerHero-left">
          <div className="textHero">
            <h2 className="textHero1">O SEGURO EXCLUSIVO</h2>
            <h2 className="textHero2">DA SUA BIKE DECATHLON</h2>
          </div>
        </div>
        <div className="separator" />
        <div className="descriptionHero">
          O seguro B•ON é descomplicado, tem amplas coberturas e oferece
          proteção imediata. Uma parceria entre a Decathlon, o Clube Santuu e a
          Assurant Seguradora.
        </div>
      </div>
    </section>
  )
}
